// extracted by mini-css-extract-plugin
export var buttomcolor = "header-module--buttomcolor--3d_Pq";
export var navbarfont = "header-module--navbarfont--39KZF";
export var logoheader = "header-module--logoheader--1cPgb";
export var logoimg = "header-module--logoimg--3GR__";
export var logoname = "header-module--logoname--2ufDi";
export var logonameul = "header-module--logonameul--1qKqj";
export var logoonename = "header-module--logoonename--1uY2Z";
export var logotwoname = "header-module--logotwoname--25VQP";
export var divtextlogo = "header-module--divtextlogo--34oXB";
export var menumobile = "header-module--menumobile--1A4UK";