import React from "react"
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap"
import * as headertopStyles from "./headertop.module.css"
import { Icon } from '@iconify/react';
import bxsPhone from '@iconify/icons-bx/bxs-phone';
import lineIcon from '@iconify/icons-cib/line';
import facebookIcon from '@iconify/icons-brandico/facebook';

const facebook = "https://www.facebook.com/ผลิตและจำหน่าย-ด้ายเย็บกระสอบ-ด้ายการ์เม้นท์และด้ายอุตสาหกรรม-ทุกชนิด-101655694919457/";
const line = "https://lin.ee/7y11wvo";
const tel081 = "tel:081-766-7977";

export default function Headertop () {
    return (
        <Container fluid="xl">
            <Row className={headertopStyles.fontheadertop}>
                <Col md={6} xs={12}><Icon icon={bxsPhone} height={18} /> สำนักงาน: <a href={tel081} style={{ color:"#050248" }}>081-766-7977</a>
                </Col>
                <Col className={headertopStyles.headertop} md={5} xs={12}>
                    <Navbar expand="lg" className="bg-body-tertiary">
                            <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Nav.Link className="head-link" href="/distributor/">ตัวแทนจำหน่าย</Nav.Link>
                                <Nav.Link className="head-link" href="/blog/">บทความ</Nav.Link>
                                <Nav.Link className="head-link" href="/about-us/">เกี่ยวกับเรา</Nav.Link>
                                <Nav.Link className="head-link" href="/contact/">ติดต่อเรา</Nav.Link>
                                <Nav className="head-top-end">|</Nav>
                            </Nav>
                            </Navbar.Collapse>
                    </Navbar>
                </Col>
                <Col className={headertopStyles.headertop} md={1} xs={12}>
                <ul className={headertopStyles.bulletsno}>
                    <li className={headertopStyles.lipaddingsocial}><a aria-label="facebook" href={facebook} target="_blank" rel="noreferrer" style={{ color:"#3B5998" }}><Icon icon={facebookIcon} height={24} /></a></li>
                    <li className={headertopStyles.lipaddingsocial}><a aria-label="line" href={line} target="_blank" rel="noreferrer" style={{ color:"#00b900" }}><Icon icon={lineIcon} height={25} /></a></li>
                </ul>
                </Col>
            </Row>
        </Container>
    );
}
