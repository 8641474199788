import React, { useState } from "react"
import { Navbar, Nav, NavDropdown, Button, Container } from "react-bootstrap"
import * as headerStyles from "./header.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';

export default function Header() {
  const [menuOpen1, toggleMenuOpen] = useState(false);
  const [menuOpen2, toggleMenuOpen2] = useState(false);
  const [menuOpen3, toggleMenuOpen3] = useState(false);
  const [menuOpen4, toggleMenuOpen4] = useState(false);
  
  return (
      <Navbar expand="lg" sticky="top">
      <Container fluid="xl">
        <Navbar.Brand href="/">
              <div>
                  <ul className={headerStyles.logoheader}>
                        <li className={headerStyles.logoimg}><StaticImage src="../../static/image/logo-stt.png" alt="Songthai" /></li>
                        <li className={headerStyles.logoname}>
                              <ul className={headerStyles.logonameul}>
                                    <li className={headerStyles.logoonename}>SongThaiTextile</li>
                                    <li className={headerStyles.logotwoname}>บริษัท ทรงไทยเท็กซ์ไทล์ จำกัด</li>
                              </ul>
                        </li>
                  </ul>
              </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={headerStyles.navbarfont}>
            <Nav className="mr-auto">
            </Nav>
            <Nav>
            <Nav.Link href="/">หน้าแรก</Nav.Link>
            <NavDropdown title="งานการ์เม้นท์" id="collasible-nav-dropdown" onMouseEnter={() => { toggleMenuOpen(true);}} onMouseLeave={() => { toggleMenuOpen(false);}} show={menuOpen1}>
                  <NavDropdown.Item href="/product/garment/เส้นด้ายเย็บ/">เส้นด้ายเย็บ</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายฟูน้อย-ฟูมาก/">ด้ายฟูน้อย/ฟูมาก</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ไนล่อน-โพลี-เย็บหนัง-เบาะ/">ไนล่อน/โพลี เย็บหนัง เบาะ</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายมัน-A-LON/">ด้ายมัน A-LON</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายเย็บโพ้งแซก/">ด้ายเย็บโพ้งแซก</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายสม็อก/">ด้ายสม็อก</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายเย็บผ้าใบ-เต้นท์/">ด้ายเย็บผ้าใบ, เต้นท์</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ด้ายเทียน/">ด้ายเทียน</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/เมจิกเทป/">เมจิกเทป</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/กรรไกร-กรรไกรก้ามปู/">กรรไกร/กรรไกรก้ามปู</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/น้ำมันจักร/">น้ำมันจักร</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/อะไหล่จักร/">อะไหล่จักร</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ไนล่อน-ซิป/">ไนล่อน ซิป</NavDropdown.Item>
                  <NavDropdown.Item href="/product/garment/ซิป-ตัวเลื่อน/">ซิป ตัวเลื่อน</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="ด้ายเย็บกระสอบ" id="collasible-nav-dropdown" onMouseEnter={() => { toggleMenuOpen2(true);}} onMouseLeave={() => { toggleMenuOpen2(false);}} show={menuOpen2}>
                  <NavDropdown.Item href="/product/sew-a-sack/ด้ายสปันเย็บกระสอบ/">ด้ายสปันเย็บกระสอบ</NavDropdown.Item>
                  <NavDropdown.Item href="/product/sew-a-sack/ด้าย-PP-multifilament-เเรงดึงสูง/">ด้าย PP multifilament เเรงดึงสูง</NavDropdown.Item>
                  <NavDropdown.Item href="/product/sew-a-sack/ด้าย-ไนล่อนเย็บกระสอบ/">ด้าย ไนล่อนเย็บกระสอบ</NavDropdown.Item>
                  <NavDropdown.Item href="/product/sew-a-sack/เครื่องจักรเย็บกระสอบ/">เครื่องจักรเย็บกระสอบ</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="ด้ายอุตสาหกรรม" id="collasible-nav-dropdown" onMouseEnter={() => { toggleMenuOpen3(true);}} onMouseLeave={() => { toggleMenuOpen3(false);}} show={menuOpen3}>
                  <NavDropdown.Item href="/product/industrial-thread/spun-polyster-yarn/">SPUN POLYESTER YARN</NavDropdown.Item>
                  <NavDropdown.Item href="/product/industrial-thread/polyester-texture-yarn/">POLYESTER TEXTURE YARN</NavDropdown.Item>
                  <NavDropdown.Item href="/product/industrial-thread/nylon-yarn/">NYLON YARN</NavDropdown.Item>
                  <NavDropdown.Item href="/product/industrial-thread/cotton-yarn/">COTTON YARN</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="อุปกรณ์ตัดเย็บ" id="collasible-nav-dropdown" onMouseEnter={() => { toggleMenuOpen4(true);}} onMouseLeave={() => { toggleMenuOpen4(false);}} show={menuOpen4}>
                  <NavDropdown.Item href="/product-import/">แค็ตาล็อก อุปกรณ์ตัดเย็บ</NavDropdown.Item>
                  <NavDropdown.Item href="/other-products/">สินค้าอื่นๆ</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className={headerStyles.menumobile} href="/distributor/">ตัวแทนจำหน่าย</Nav.Link>
            <Nav.Link className={headerStyles.menumobile} href="/blog/">บทความ</Nav.Link>
            <Nav.Link className={headerStyles.menumobile} href="/about-us/">เกี่ยวกับเรา</Nav.Link>
            <Nav.Link className={headerStyles.menumobile} href="/contact/">ติดต่อเรา</Nav.Link>
            </Nav>
            <Button className={headerStyles.buttomcolor} rel="noreferrer" href="/contact/">ขอใบเสนอราคา <Icon icon={arrowRight} /></Button>{' '}
        </Navbar.Collapse>
      </Container>
      </Navbar>
  );
}